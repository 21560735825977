import { CheckingInvalidRecipient } from '@/presentation/recipient/model/CheckingInvalidRecipient'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import { IRecipient } from '@/usecase/recipient/model/IRecipient'
import MemberInfo from '@/models/MemberInfo'
import { ReceiveMethodEnum } from '@/enums'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import { accountTypes } from '@/data/AccountType'
import { MasterCode } from '@/gateway/sheet/MasterCode'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import { IGetMasterCodeOption } from '@/entity/validator/data/RecipientRules'
import { IdCardTypeEnum } from '@/enums/IdCardTypeEnum'
import { formattersMap } from '@/lib/Utils'
import { Utils } from '@/static/Utils'
import { usdAnyWhereMasterCodes } from '@/data/masterCodes/UsdAnywhereMasterCodes'

export interface IRegisterRecipientPresentation {
  countryWithMethods: Array<string>
  recipients: Dictionary<Array<CheckingInvalidRecipient>>
  recipientsWithPages: Dictionary<Dictionary<Array<CheckingInvalidRecipient>>>
  selectedCountryWithMethod: string
  totalRecipientCount: Dictionary<number>
  page: number
  unit: number
  isEmptyRecipients: boolean
  hasUpdatingRecipients: boolean

  deleteRow(countryWithMethod: string, index: number): void
  toggleUpdatable(countryWithMethod: string, index: number, callback: any): void
  register(): Promise<boolean>
  setRecipientsPagination(): void
  initialize(validatedRecipients: Dictionary<Array<CheckingInvalidRecipient>>): void
  getCountryMethodLabel (countryWithMethod: string): string
}

export class RegisterRecipientPresentation implements IRegisterRecipientPresentation {
  public countryWithMethods: Array<string> = []
  public recipients: Dictionary<Array<CheckingInvalidRecipient>> = {}
  public recipientsWithPages: Dictionary<Dictionary<Array<CheckingInvalidRecipient>>> = {}
  public selectedCountryWithMethod: string = ''
  public totalRecipientCount: Dictionary<number> = {}
  public page = 1
  public readonly unit: number = 20
  private recipientUseCase: IRecipientUseCase

  constructor() {
    this.recipientUseCase = new RecipientUseCase()
  }

  get isEmptyRecipients(): boolean {
    const isEmpty = Object.values(this.totalRecipientCount).every(count => count === 0)
    return isEmpty
  }

  get hasUpdatingRecipients(): boolean {
    let hasUpdating = false
    this.countryWithMethods.forEach(countryWithMethod => {
      const isUpdating: boolean = this.recipients[countryWithMethod].some(recipient => {
        return !!recipient.updatable
      })
      hasUpdating = hasUpdating || isUpdating
    })
    return hasUpdating
  }

  public deleteRow(countryWithMethod: string, index: number): void {
    const pageRecipient = this.recipientsWithPages[countryWithMethod][this.page][index]
    this.recipientsWithPages[countryWithMethod][this.page].splice(index, 1)
    const recipientIndex = this.recipients[countryWithMethod].findIndex(recipient => recipient.id === pageRecipient.id)
    this.recipients[countryWithMethod].splice(recipientIndex, 1)
    this.setRecipientsPagination()
    this.totalRecipientCount[countryWithMethod]--
  }

  public toggleUpdatable(countryWithMethod: string, index: number, callback: any): void {
    const recipientPageRow = this.recipientsWithPages[countryWithMethod][this.page][index]
    const recipientRow = this.recipients[countryWithMethod].find(recipient => recipient.id === recipientPageRow.id)
    if (callback) callback()
    if (!recipientRow) return
    recipientRow.updatable = recipientRow.updatable === true ? !recipientRow.updatable : true
    this.recipients[countryWithMethod] = Array(...this.recipients[countryWithMethod])
  }

  private convertToRecipientModel(
    recipient: CheckingInvalidRecipient,
    countryWithMethod: string,
    corpId: string
  ): IRecipient {
    const { country, method, currency } = Utils.splitCountryAndMethod(countryWithMethod) 
    const methodType: ReceiveMethodEnum = ReceiveMethodEnum[method]
    const getMasterCodeOption: IGetMasterCodeOption = {
      country: currency ? `${country}_${currency}` : country,
      method,
      masterCodeLabel: recipient.master_code,
      receiveCurrency: recipient.base_currency
    }
    const masterCode = methodType === ReceiveMethodEnum.USD_ANYWHERE 
      ? usdAnyWhereMasterCodes[country]
      : MasterCode.getMasterCode(getMasterCodeOption)
    const isNumericPhoneCode = Number(recipient.phone_code)
    const phoneCode =
      isNumericPhoneCode ||
      CountryPhoneCodes.find(countryCode => {
        return countryCode.iso === recipient.phone_code?.toString().toUpperCase()
      })?.phone_code ||
      CountryPhoneCodes.find(countryCode => {
        return countryCode.iso === country.toUpperCase()
      })?.phone_code
    const phoneNumber = recipient.phone.toString().trim()
    const accountType = recipient.account_type
      ? accountTypes[recipient.account_type.toUpperCase()] || recipient.account_type.toUpperCase()
      : undefined
    const idCardTypeNumber = recipient.id_card_type?.toString().toUpperCase().replace(/ /gi, '_')
    const idCardType = recipient.id_card_type
      ? IdCardTypeEnum[idCardTypeNumber as keyof typeof IdCardTypeEnum]
      : undefined
    const addressPostal = recipient.address_postal
    // for previous excel compatible
    const recipientObject = recipient as Dictionary<any>
    const routingNumber = recipientObject.electronic_routing_number || recipient.routing_number
    const cardNumber = recipientObject.debit_card_number || recipient.card_number
    return {
      corp_id: corpId,
      corp_pid: recipient.corps_id,
      first_name: recipient.name_first,
      middle_name: recipient.name_middle,
      last_name: recipient.name_last,
      phone_number: phoneNumber,
      phone_code: phoneCode || 0,
      country: country,
      currency: recipient.base_currency as string,
      nationality: recipient.nation,
      birth: recipient.birth_date,
      master_code: masterCode ? Number(masterCode).toString() : undefined,
      remittance_method_type: methodType,
      remittance_method_data: {
        bank_account_number: recipient.account_number,
        bank_account_type: accountType,
        bank_branch: recipient.bank_branch,
        bank_bsb_code: recipient.bsb_code,
        bank_iban: recipient.iban,
        bank_ifsc: recipient.ifsc,
        bank_routing_number: routingNumber,
        bank_sort_code: recipient.sort_code,
        bank_swift: recipient.swift,
        corresponding_bank_swift: recipient.corresponding_bank_swift,
        bank_transit_code: recipient.transit_code,
        payment_gateway_wing_money_account_number: recipient.wingmoney_number,
        debit_card_number: cardNumber
      },
      id_document_type: idCardType,
      id_document_number: recipient.id_card_number,
      line1: recipient.address_line1,
      line2: recipient.address_line2,
      city: recipient.address_city || recipient.address_region, // city and region are same
      region: recipient.address_city || recipient.address_region, // city and region are same
      postal_code: addressPostal
    }
  }

  public async register(): Promise<boolean> {
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const registerRecipients: Array<IRecipient> = []
    this.countryWithMethods.forEach(countryWithMethod => {
      this.recipients[countryWithMethod].forEach(recipient => {
        const registerRecipient = this.convertToRecipientModel(recipient, countryWithMethod, memberInfo.corp_id)
        registerRecipients.push(registerRecipient)
      })
    })
    const isSuccessResponse = await this.recipientUseCase.registerRecipients({ list: registerRecipients })
    return isSuccessResponse
  }

  private setTotalCount(countryWithMethod: string) {
    this.totalRecipientCount[countryWithMethod] = this.recipients[countryWithMethod].length
  }

  public setRecipientsPagination() {
    this.countryWithMethods.forEach(countryWithMethod => {
      if (!this.recipientsWithPages[countryWithMethod]) this.recipientsWithPages[countryWithMethod] = {}
      const recipients: Array<CheckingInvalidRecipient> = new Array(...this.recipients[countryWithMethod])
      let page: number = 1
      while (recipients.length > 0) {
        this.recipientsWithPages[countryWithMethod][page++] = recipients.splice(0, this.unit)
      }
      this.setTotalCount(countryWithMethod)
    })
  }

  getCountryMethodLabel(countryWithMethod: string): string {
    const translated = formattersMap.countryWithMethod(countryWithMethod, true)
    const isTranslated = !translated.includes('.')
    if (isTranslated) return translated
    
    const translatedMethod = translated.split(' ')[1]
    const country = countryWithMethod.split('_')[0]
    const otherCountry = CountryPhoneCodes.find(countryPhone => countryPhone.iso === country.toUpperCase())
    const otherCountryName = Utils.isKoreanLocale ? otherCountry?.name_korean : otherCountry?.name
    return `${otherCountryName || country.toUpperCase()} ${translatedMethod}`
  }

  public initialize(validatedRecipients: Dictionary<Array<CheckingInvalidRecipient>>) {
    this.recipients = Object.assign({}, validatedRecipients)
    this.countryWithMethods = Object.keys(validatedRecipients)
    this.selectedCountryWithMethod = this.countryWithMethods[0]
    this.recipientsWithPages = {}
    this.totalRecipientCount = {}
    this.setRecipientsPagination()
  }
}
