import { regexToPatternString } from '@/data/Regex'
import { RuleItem } from 'async-validator'
import i18n from '@/plugins/i18n'
import ManageTableOptions from '@/models/forms/ManageTableOptions'
import { IdCardTypeEnum } from '@/enums/IdCardTypeEnum'

const inputText: ManageTableOptions = { type: 'input', inputType: 'text' }
const inputNumber: ManageTableOptions = { type: 'input', inputType: 'number' }
export const registerRecipientOptions: Dictionary<ManageTableOptions> = {
  id: { type: 'hide' },
  base_currency: {
    type: 'select',
    label: i18n.t('commons.receive_currency') as string,
    option: 'receivableCountryCurrencies',
    width: 100
  },
  master_code: Object.assign({}, inputText, { width: 180 }),
  phone_code: Object.assign({}, inputText, { width: 100 }),
  phone: { ...inputText, width: 130 },
  bank_branch: inputNumber,
  account_number: Object.assign({}, inputText, { width: 140 }),
  account_type: inputText,
  card_number: { ...inputNumber, width: 160 },
  id_card_number: { ...inputText, width: 160 },
  id_card_type: inputText,
  id_card_date_of_issue: inputText,
  id_card_place_of_issue: inputText,
  address_line1: Object.assign({}, inputText, { width: 170, label: i18n.t('sheet.field.line1') as string }),
  address_line2: Object.assign({}, inputText, { width: 170, label: i18n.t('sheet.field.line2') as string }),
  address_postal: { ...inputText, label: i18n.t('sheet.field.postal') as string },
  address_region: { ...inputText, label: i18n.t('sheet.field.region') as string },
  address_city: { ...inputText, label: i18n.t('sheet.field.city') as string },
  iban: inputText,
  routing_number: {
    ...inputNumber,
    label: i18n.t('sheet.field.bank_routing_number') as string,
    width: 160
  },
  ifsc: inputText,
  bsb_code: inputNumber,
  swift: inputText,
  sort_code: inputText,
  wingmoney_number: { ...inputNumber, width: 150 },
  transit_code: Object.assign({}, inputNumber, { width: 100 }),
  email: inputText,
  birth_date: { ...inputText, width: 100 },
  id_card_expire: inputText,
  nation: Object.assign({}, inputText, { width: 80 }),
  corresponding_bank_swift: inputText,
}
Object.keys(registerRecipientOptions).forEach((prop: string) => {
  registerRecipientOptions[prop].width = registerRecipientOptions[prop].width || prop.length * 15
})

const additionalInvalidOption = {
  corps_id: { ...inputText, width: 120 },
  name_first: { ...inputText, width: 150 },
  name_middle: { ...inputText, width: 80 },
  name_last: { ...inputText, width: 100 }
}
export const invalidRegisterRecipientOptions = Object.assign({}, registerRecipientOptions, additionalInvalidOption)

const required: RuleItem = { required: true, message: 'this field is required' }
const requiredAndNumber: RuleItem = { ...required, pattern: regexToPatternString('number'), message: 'number only' }
const requiredNumberEnglish: RuleItem = {
  ...required,
  pattern: regexToPatternString('numberEnglish'),
  message: 'number and english only'
}
export const recipientRuleDescriptor: Dictionary<RuleItem | Array<RuleItem>> = {
  corps_id: {
    ...required,
    pattern: regexToPatternString('corps_id'),
    message: 'number and english only'
  },
  base_currency: required,
  master_code: required,
  phone_code: [
    required,
    {
      pattern: regexToPatternString('numberEnglishWithoutBlank'),
      message: 'invalid phone code'
    }
  ],
  phone: requiredAndNumber,
  bank_branch: requiredAndNumber,
  account_number: {
    ...required,
    pattern: regexToPatternString('account_number')
  },
  account_type: required,
  card_number: requiredAndNumber,
  id_card_number: {
    ...required,
    pattern: regexToPatternString('id_card_number')
  },
  id_card_type: [
    required,
    {
      validator: (rule, value) => {
        if (!value) return false
        const inputIdCardTypeName = value.toString().toUpperCase().replace(/_/gi, ' ')
        return Object.keys(IdCardTypeEnum).some(type => {
          const enumTypeName = type.toString().toUpperCase().replace(/_/gi, ' ')
          return enumTypeName === inputIdCardTypeName
        })
      },
      message: 'invalid id card type'
    }
  ],
  id_card_date_of_issue: {
    ...required,
    pattern: regexToPatternString('date')
  },
  id_card_place_of_issue: required,
  address_line1: {
    ...required,
    pattern: regexToPatternString('address_line1')
  },
  address_postal: required,
  address_region: requiredNumberEnglish,
  address_city: requiredNumberEnglish,
  iban: required,
  routing_number: requiredAndNumber,
  ifsc: required,
  bsb_code: requiredAndNumber,
  swift: required,
  sort_code: required,
  wingmoney_number: requiredAndNumber,
  transit_code: requiredAndNumber,
  email: {
    ...required,
    pattern: regexToPatternString('email')
  },
  birth_date: {
    ...required,
    pattern: regexToPatternString('birth_date')
  },
  id_card_expire: {
    ...required,
    pattern: regexToPatternString('date')
  },
  nation: {
    ...required,
    pattern: regexToPatternString('alpha-2')
  }
}
